import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import MainFooter from "../components/footer/MainFooter";

export default function QuoteForm() {
  const [formStatus, setFormStatus] = useState(false);
  const [query, setQuery] = useState({
    service: "",
    name: "",
    email: "",
    from_address: "",
    from_country: "",
    to_address: "",
    to_country: "",
  });

  const handleChange = () => (e) => {
    console.log(e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios({
      method: "post",
      url: "https://v1.nocodeapi.com/micholusanya/google_sheets/dXWSODQAHPQTuAMP?tabId=Sheet1",
      params: {},
      data: [Object.values(query)],
    })
      .then(function (response) {
        setFormStatus(true);
        setQuery({
          service: "",
          name: "",
          email: "",
          from_address: "",
          from_country: "",
          to_address: "",
          to_country: "",
        });
        console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  return (
    <div className="App">
      <form onSubmit={handleSubmit} className="container mt-5">
        <h4>Fill the form below to get a quote</h4>
        <TextField
          autoFocus
          margin="dense"
          id="service"
          label="Service Type"
          type="service"
          name="service"
          value={query.service}
          onChange={handleChange()}
          fullWidth
        />

        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          name="email"
          value={query.email}
          onChange={handleChange()}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Full Name"
          type="text"
          name="name"
          value={query.name}
          onChange={handleChange()}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          id="from_address"
          label="From Address"
          type="text"
          name="from_address"
          value={query.from_address}
          onChange={handleChange()}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          id="from_country"
          label="From Country"
          type="text"
          name="from_country"
          value={query.from_country}
          onChange={handleChange()}
          fullWidth
        />

        <TextField
          autoFocus
          margin="dense"
          id="to_country"
          label="To Country"
          type="text"
          name="to_country"
          value={query.to_country}
          onChange={handleChange()}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          id="to_address"
          label="To Address"
          type="text"
          name="to_address"
          value={query.to_address}
          onChange={handleChange()}
          fullWidth
        />

        <Button type="reset" color="primary">
          Cancel
        </Button>
        <Button type="submit" color="primary">
          Submit
        </Button>
        {formStatus ? (
          <div className="alert alert-success">Your message has been sent.</div>
        ) : (
          ""
        )}
      </form>
      <MainFooter />
    </div>
  );
}
