import React from "react";

function Portal(props) {
  return (
    <div>
      <h1>Customer Portal</h1>
    </div>
  );
}

export default Portal;
