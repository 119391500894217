import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import img5 from "../../static-images/gallery/3.jpg";

const useStyles = makeStyles({
  root: {
    maxWidth: 1200,
  },
});

export default function ServiceCard() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Service"
          height="240"
          image={img5}
          title="Service Overview"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Overview
          </Typography>
          <Typography
            className="text-center"
            variant="body2"
            color="textSecondary"
            component="p"
          >
            We are professionals and we do our work professionally. See our core
            services below:
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions></CardActions>
    </Card>
  );
}
